import type model from './model';
import type { TFunction, IWixWindow } from '@wix/yoshi-flow-editor';
import { ITEM_MODAL_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { buildImgSrc, getAltText } from '../Dishes/utils';
import type {
  ModifierListItem,
  ModifierRepeaterData,
  RepeaterModifierGroupListItem,
} from '../../types/modifiers';
import type { DishModalProps } from '../../types/widgetsProps';
import type { ItemData } from '../../types/item';
import type {
  ADD_TO_CART_ERRORS,
  CartLineItem,
  CatalogReferencePriceVariant,
} from '../../services/cartService';
import { sumAdditionalCharges } from '../../utils/modifiersUtils';
import { getVariantById, getVariantWithMinimalPrice } from '../../utils/priceVariantsUtils';
import { MODIFIER_RULE_STATES } from '../../types/businessTypes';
import { state, DEFAULT_QUANTITY } from '../../states/ItemModalState';
import type { ControllerParams } from '../../types/widgets';
import {
  getFirstPreSelectedModifier,
  getModifierGroupErrorText,
  getModifierGroupLabel,
  getPreSelectedModifiers,
  isSelectedModifierGroupsValid,
  isSingleSelectRule,
} from 'root/utils/modifierGroupUtils';
import {
  getModifierGroupsOptions,
  getModifierListItem,
  getModifiersOptions,
} from 'root/utils/itemModalControllerUtils';
import * as itemModalControllerUtils from 'root/utils/itemModalControllerUtils';
import type { PriceFormatter } from '@wix/restaurants-olo-operations-client-commons';
import type { PriceVariantInItem } from 'root/types/priceVariants';
import type { LabelWithSvg } from 'root/types/label';

type BindAll = ControllerParams<typeof model>['$bindAll'];
type Bind = ControllerParams<typeof model>['$bind'];
type I$W = ControllerParams<typeof model>['$w'];

export class ItemModalController {
  private formatCurrency: (price?: number) => string;

  constructor(
    private $bindAll: BindAll,
    private $bind: Bind,
    private $w: I$W,
    private t: TFunction,
    priceFormatter: PriceFormatter,
    private window: IWixWindow,
    private isEditor: boolean
  ) {
    this.formatCurrency = (price?: number) => {
      return price ? priceFormatter(price) : '';
    };
  }

  initEditItem = (cartItem: CartLineItem, priceVariants: PriceVariantInItem[]) => {
    const { quantity, options } = cartItem;
    const { specialRequests, priceVariant } = options ?? {};
    state.quantity = quantity || DEFAULT_QUANTITY;
    state.specialRequests = specialRequests ?? '';
    if (priceVariant?.id) {
      const selectedPriceVariant = getVariantById({
        priceVariantsInItem: priceVariants,
        variantId: priceVariant.id,
      });
      if (selectedPriceVariant) {
        state.selectedPriceVariant = selectedPriceVariant;
      }
    }
  };

  init(
    dishModalProps: DishModalProps,
    onModalOpen: () => void = () => {},
    closeModal: (
      window: IWixWindow,
      dataPromise: Promise<{ cartItem?: CartLineItem; error?: ADD_TO_CART_ERRORS }> | undefined,
      cartLineItem?: CartLineItem
    ) => void = () => {},
    priceVariants: PriceVariantInItem[],
    modifierGroups: RepeaterModifierGroupListItem[]
  ) {
    const {
      item,
      cartService,
      biReporterService,
      operationId,
      canAcceptOrders,
      cartItem,
      menuId,
      sectionId,
      isMenuOfItemAvailable = true,
    } = dishModalProps;

    if (item) {
      state.amount = item.price.amount;
      state.currency = item.price.currency;
      const isInStockItem = item.orderSettings?.inStock ?? true;
      const isValidToContinue = canAcceptOrders && isInStockItem && isMenuOfItemAvailable;

      if (cartItem) {
        this.initEditItem(cartItem, priceVariants);
      }

      if (priceVariants?.length) {
        const variantWithMinimalPriceString = state.selectedPriceVariant.id
          ? state.selectedPriceVariant.price
          : getVariantWithMinimalPrice(priceVariants)?.price;
        const variantWithMinimalPrice = Number(variantWithMinimalPriceString || 0);
        state.amount = variantWithMinimalPrice;
        state.basePrice = variantWithMinimalPrice;
      }

      const hasImageUrl = !!item.image?.url;

      const imageProps = hasImageUrl
        ? {
            type: 'image',
            src: item.image && (buildImgSrc(item.image) as string),
            alt: getAltText({ itemName: item.name, t: this.t }),
          }
        : { type: 'image', src: '' };
      const acceptSpecialRequests = item.orderSettings?.acceptSpecialRequests;

      const hasLabels = item.labels?.length;
      const hasPriceVariants = Boolean(priceVariants?.length);
      const hasModifierGroups = Boolean(modifierGroups?.length);

      hasLabels && this.initLabels(item);
      priceVariants?.length && this.initPriceVariants(priceVariants);
      modifierGroups?.length && this.initModifiers(modifierGroups);

      this.$w(ITEM_MODAL_COMPONENT_IDS.counterInput).hideNumberSpinner();

      this.$bindAll({
        [ITEM_MODAL_COMPONENT_IDS.counterContainer]: {
          collapsed: () => !isValidToContinue,
        },
        [ITEM_MODAL_COMPONENT_IDS.counterInput]: {
          value: () => state.quantity.toString(),
          onInput: (evt: $w.Event) => (state.quantity = Number(evt.target.value)),
          onChange: (evt: $w.Event) => {
            const quantity = Number(evt.target.value);
            state.quantity = quantity < 1 ? DEFAULT_QUANTITY : quantity;
          },
        },
        [ITEM_MODAL_COMPONENT_IDS.increaseButton]: {
          collapsed: () => !isValidToContinue,
          onClick: () => state.quantity++,
        },
        [ITEM_MODAL_COMPONENT_IDS.decreaseButton]: {
          collapsed: () => !isValidToContinue,
          disabled: () => state.quantity === 1,
          onClick: () => state.quantity--,
        },
        [ITEM_MODAL_COMPONENT_IDS.title]: {
          text: () => item.name || '',
        },
        [ITEM_MODAL_COMPONENT_IDS.subTitle]: {
          text: () => item.description || '',
          collapsed: () => !item.description,
        },
        [ITEM_MODAL_COMPONENT_IDS.addToCart]: {
          onClick: async () => {
            const selectedModifierGroups = getModifierGroupsOptions(
              modifierGroups,
              state.selectedModifiers,
              this.formatCurrency
            );
            const shouldAddToCart = modifierGroups
              ? this.validateSelectedModifierGroups(modifierGroups)
              : true;

            this.updateModifiersElementValidationStatus();

            let cartResponsePromise;
            if (shouldAddToCart) {
              const options = {
                priceVariant: this.getPriceVariantOptions(state.selectedPriceVariant),
                modifierGroups: selectedModifierGroups,
                operationId,
                menuSectionId: sectionId,
                menuId,
                specialRequests: state.specialRequests !== '' ? state.specialRequests : undefined,
              };
              const cartLineItem = {
                catalogItemId: item._id ?? item.id,
                quantity: state.quantity,
                options,
              };
              cartResponsePromise = cartService?.addItemToCart(
                cartLineItem,
                cartItem?.id ?? undefined
              );
              closeModal(this.window, cartResponsePromise, cartLineItem);
            }
            const numSelectedModifiers =
              selectedModifierGroups?.reduce(
                (prevValue, currValue) => prevValue + (currValue.modifiers?.length || 0),
                0
              ) || 0;

            const value = await cartResponsePromise;

            biReporterService?.reportOloLiveSiteAddDishToCartBiEvent({
              itemName: item.name,
              itemId: item._id ?? item.id,
              menuId,
              sectionId,
              num_labels: item.labels?.length || 0,
              labelsList: JSON.stringify(item.labels || []),
              amountOfItems: state.quantity,
              numSelectedModifiers,
              priceVariationName: state.selectedPriceVariant.name,
              selectedModifiersList: JSON.stringify(selectedModifierGroups),
              itemPrice: state.amount * state.quantity,
              isSuccess: Boolean(!value?.error),
              operationId,
            });
          },
          label: () =>
            this.getAddToCartButtonText(
              item,
              state.quantity,
              state.amount,
              state.currency,
              isValidToContinue,
              !!cartItem,
              isMenuOfItemAvailable
            ),
          disabled: () => !isValidToContinue,
        },
        [ITEM_MODAL_COMPONENT_IDS.close]: {
          onClick: async () => closeModal(this.window, undefined),
        },
        [ITEM_MODAL_COMPONENT_IDS.image]: {
          src: () => imageProps.src ?? '',
          alt: () => imageProps.alt ?? '',
          collapsed: () => {
            !hasImageUrl && onModalOpen();
            return !hasImageUrl;
          },
          onViewportEnter: () => {
            hasImageUrl && onModalOpen();
          },
        },
        [ITEM_MODAL_COMPONENT_IDS.labelsContainer]: {
          hidden: () => !hasLabels,
          collapsed: () => !hasLabels,
        },
        [ITEM_MODAL_COMPONENT_IDS.specialRequest]: {
          placeholder: () => this.t('itemModal.specialRequests.placeholder'),
          label: () => this.t('itemModal.specialRequests.label'),
          collapsed: () => !acceptSpecialRequests,
          disabled: () => !isValidToContinue,
          onChange: (evt: $w.Event) => (state.specialRequests = evt.target.value),
          value: !this.isEditor ? () => state.specialRequests : undefined,
        },
        [ITEM_MODAL_COMPONENT_IDS.quantityText]: {
          text: () => this.t('itemModal.quantity.text'),
        },
        [ITEM_MODAL_COMPONENT_IDS.priceVariantsSingleSelect]: {
          collapsed: () => !hasPriceVariants,
        },
        [ITEM_MODAL_COMPONENT_IDS.modifiersContainer]: {
          collapsed: () => !hasModifierGroups,
        },
      });
      const numOfModifiers = modifierGroups?.reduce(
        (prevValue, currValue) => prevValue + (currValue.modifiers?.length || 0),
        0
      );
      biReporterService?.reportOloLiveSiteItemModalOpenedBiEvent({
        itemName: item.name,
        itemId: item._id ?? item.id,
        menuId,
        sectionId,
        minItemPrice: state.amount || 0,
        num_labels: item.labels?.length || 0,
        numModifiers: numOfModifiers || 0,
        numModifiersGroups: modifierGroups?.length || 0,
        numPriceVariations: priceVariants?.length || 0,
        isMenuItemAvailable: isMenuOfItemAvailable,
        isItemInStock: isInStockItem,
        operationId,
      });
    }
  }

  getFormattedPriceVariantLabel(name: string, price: string) {
    return `${name} (${price})`;
  }

  getAddToCartButtonText(
    item: ItemData,
    quantity: number,
    amount: number,
    currency: string,
    isValidToContinue: boolean,
    editMode: boolean,
    isMenuOfItemAvailable: boolean
  ): string {
    const isInStockItem = item.orderSettings?.inStock ?? true;
    const formattedPrice = this.formatCurrency(quantity * amount);

    if (!isMenuOfItemAvailable) {
      return this.t('itemModal.itemUnavailable.addToCartButton', {
        price: formattedPrice,
      });
    }

    if (!isInStockItem) {
      return this.t('itemModal.outOfStock.addToCartButton', {
        price: formattedPrice,
      });
    }

    if (!isValidToContinue) {
      return this.t('itemModal.notValid.addToCartButton', {
        price: formattedPrice,
      });
    }

    if (editMode) {
      return this.t('itemModal.editMode.addToCartButton', {
        price: formattedPrice,
      });
    }

    return this.t('itemModal.addToCartButton', { price: formattedPrice });
  }

  async initLabels(item: ItemData) {
    const labelsWithSvg = await itemModalControllerUtils.getLabelsWithSvgElement(item.labels);
    this.$bind(ITEM_MODAL_COMPONENT_IDS.labelsContainer, {
      data: () => labelsWithSvg,
      item: (label: LabelWithSvg, bindItem: Bind) => {
        const { svgId } = label;
        const iconSrc = svgId ? { icon: () => ({ svgId }) } : {};
        bindItem(ITEM_MODAL_COMPONENT_IDS.label, {
          ...iconSrc,
          // @ts-expect-error - badge element generated as HiddenCollapsedElement
          text: () => label.name,
          collapsed: () => !svgId && !label.name,
        });
      },
    });
  }

  getSelectedPriceVariantIndex = (
    priceVariants: PriceVariantInItem[],
    selectedPriceVariant: string
  ) => {
    const selectedIndex = priceVariants.findIndex(({ id }) => id === selectedPriceVariant);
    return selectedIndex > 0 ? selectedIndex : 0;
  };

  initPriceVariants(priceVariants: PriceVariantInItem[]) {
    if (!state.selectedPriceVariant.id) {
      state.selectedPriceVariant = priceVariants && getVariantWithMinimalPrice(priceVariants);
    }
    const priceVariantsOptions = priceVariants.map((priceVariant) => {
      const formattedPrice = this.formatCurrency(Number(priceVariant.price));
      const label = this.getFormattedPriceVariantLabel(priceVariant.name ?? '', formattedPrice);
      return {
        label,
        value: priceVariant.id,
      };
    });
    const currentPriceVariantElement = ITEM_MODAL_COMPONENT_IDS.priceVariantsSingleSelect;

    this.$bindAll({
      [currentPriceVariantElement]: {
        collapsed: () => !priceVariants,
      },
    });

    if (priceVariants) {
      this.$bindAll({
        [currentPriceVariantElement]: {
          options: () => priceVariantsOptions,
          label: () => this.t('menu-olo.item-modal.price-variants'),
          required: () => true,
          onChange: (event: $w.Event) => {
            state.selectedPriceVariant =
              priceVariants.find((priceVariant) => {
                return priceVariant.id === event.target.value;
              }) ?? {};
            const price = priceVariants.find((priceVariant) => {
              return priceVariant.id === state.selectedPriceVariant.id;
            })?.price;
            state.amount = state.additionalCharge + Number(price);
            state.basePrice = Number(price);
          },
          selectedIndex: () =>
            this.getSelectedPriceVariantIndex(priceVariants, state.selectedPriceVariant.id ?? ''),
        },
      });
    }
  }

  calculateAddToCartButtonPrice() {
    const sum = sumAdditionalCharges(state.selectedModifiers);
    state.amount = state.amount - state.additionalCharge + sum;
    state.additionalCharge = sum;
  }

  setSelectedModifiers(modifierGroupId: string, modifierListItems: ModifierListItem[]) {
    state.selectedModifiers = {
      ...state.selectedModifiers,
      [modifierGroupId]: modifierListItems,
    };
    this.calculateAddToCartButtonPrice();
  }

  initPreSelectedModifiers(modifierGroups?: RepeaterModifierGroupListItem[]) {
    modifierGroups?.forEach(({ id, modifiers, rule }) => {
      if (modifiers) {
        const isMultiSelectItem = !isSingleSelectRule(rule ?? {});

        if (isMultiSelectItem) {
          const preSelectedModifiers = getPreSelectedModifiers(modifiers);
          if (preSelectedModifiers.length) {
            this.setSelectedModifiers(id, preSelectedModifiers);
          }
        } else {
          const preSelectedModifier = getFirstPreSelectedModifier(modifiers);

          if (preSelectedModifier) {
            this.setSelectedModifiers(id, [preSelectedModifier]);
          }
        }
      }
    });
  }

  initModifiers(modifierGroups: RepeaterModifierGroupListItem[]) {
    this.initPreSelectedModifiers(modifierGroups);

    this.$bind(ITEM_MODAL_COMPONENT_IDS.modifiersContainer, {
      data: () =>
        modifierGroups.map((modifierRepeater) => ({
          _id: modifierRepeater.id,
          ...modifierRepeater,
        })) || [],
      item: (modifierRepeaterData: ModifierRepeaterData, bindItem: Bind) => {
        const isMultiSelectItem = !isSingleSelectRule(modifierRepeaterData.rule ?? {});
        const modifiersOptions = getModifiersOptions(
          modifierRepeaterData.modifiers ?? [],
          this.formatCurrency
        );
        const isRequiredGroup = modifierRepeaterData.rule?.required;

        bindItem(ITEM_MODAL_COMPONENT_IDS.multiSelect, {
          onChange: () => {
            state.modifiersErrors[modifierRepeaterData.id] = false;
          },
        });
        if (isMultiSelectItem) {
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersMultiState, {
            currentState: () => MODIFIER_RULE_STATES.multiSelect,
          });
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersError, {
            collapsed: () => !state.modifiersErrors[modifierRepeaterData.id],
            hidden: () => !state.modifiersErrors[modifierRepeaterData.id],
          });
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersErrorText, {
            text: () => getModifierGroupErrorText(this.t, modifierRepeaterData),
          });
        } else {
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersSingleError, {
            collapsed: () => !state.modifiersErrors[modifierRepeaterData.id],
            hidden: () => !state.modifiersErrors[modifierRepeaterData.id],
          });
          bindItem(ITEM_MODAL_COMPONENT_IDS.modifiersSingleErrorText, {
            text: () => getModifierGroupErrorText(this.t, modifierRepeaterData),
          });
        }

        if (this.isEditor) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$w(ITEM_MODAL_COMPONENT_IDS.modifiersError) as unknown as any).delete();
        }

        if (isMultiSelectItem) {
          bindItem(ITEM_MODAL_COMPONENT_IDS.multiSelect, {
            options: () => modifiersOptions,
            required: () => !!isRequiredGroup,
            label: () => getModifierGroupLabel(this.t, modifierRepeaterData),
            onChange: (event: $w.Event) => {
              const modifierListItems = event.target.value.map((value: string) =>
                getModifierListItem(modifierRepeaterData?.modifiers || [], value)
              );

              this.setSelectedModifiers(modifierRepeaterData?.id, modifierListItems);
            },
            value: () => state.selectedModifiers[modifierRepeaterData.id]?.map(({ id }) => id!),
            // @ts-expect-error
            max: () => modifierRepeaterData.rule?.maxSelections ?? undefined,
            min: () => modifierRepeaterData.rule?.minSelections,
          });
        } else {
          bindItem(ITEM_MODAL_COMPONENT_IDS.singleSelect, {
            options: () => modifiersOptions,
            required: () => !!isRequiredGroup,
            label: () => getModifierGroupLabel(this.t, modifierRepeaterData),
            onChange: (event: $w.Event) => {
              const modifierListItem = getModifierListItem(
                modifierRepeaterData?.modifiers || [],
                event.target.value
              );

              if (modifierListItem) {
                this.setSelectedModifiers(modifierRepeaterData?.id, [modifierListItem]);
              }
            },
            value: () => state.selectedModifiers[modifierRepeaterData.id]?.at(0)?.id!,
          });
        }
      },
    });

    this.resetModifiersElementValidationStatus();
  }

  getPriceVariantOptions(selectedPriceVariant: ModifierListItem) {
    return selectedPriceVariant?.id
      ? ({
          id: state.selectedPriceVariant.id,
          formattedPrice: this.formatCurrency(Number(state.selectedPriceVariant.price)),
        } as CatalogReferencePriceVariant)
      : undefined;
  }

  validateSelectedModifierGroups(modifierGroups: RepeaterModifierGroupListItem[]) {
    const { isValid, errorsState } = isSelectedModifierGroupsValid(
      state.selectedModifiers,
      modifierGroups
    );

    state.modifiersErrors = errorsState;

    return isValid;
  }

  resetModifiersElementValidationStatus() {
    this.$w(ITEM_MODAL_COMPONENT_IDS.modifiersContainer).onItemReady(
      ($item, modifierRepeaterData: ModifierRepeaterData) => {
        const isMultiSelectItem = !isSingleSelectRule(modifierRepeaterData.rule ?? {});
        const selectionComponentId = isMultiSelectItem
          ? ITEM_MODAL_COMPONENT_IDS.multiSelect
          : ITEM_MODAL_COMPONENT_IDS.singleSelect;

        $item(selectionComponentId).resetValidityIndication();
      }
    );
  }

  updateModifiersElementValidationStatus() {
    this.$w(ITEM_MODAL_COMPONENT_IDS.modifiersContainer).forEachItem(
      ($item, modifierRepeaterData: ModifierRepeaterData) => {
        const isMultiSelectItem = !isSingleSelectRule(modifierRepeaterData.rule ?? {});
        const selectionComponentId = isMultiSelectItem
          ? ITEM_MODAL_COMPONENT_IDS.multiSelect
          : ITEM_MODAL_COMPONENT_IDS.singleSelect;

        $item(selectionComponentId).updateValidityIndication();
      }
    );
  }
}
