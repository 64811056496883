import { makeAutoObservable } from 'mobx';
import { DEFAULT_CURRENCY_CODE } from '../api/consts';
import type { ModifierListItem } from '../types/modifiers';

export const DEFAULT_QUANTITY = 1;
const DEFAULT_AMOUNT = 0;
const DEFAULT_ADDITIONAL_CHARGE = 0;

export const state = makeAutoObservable<{
  quantity: number;
  amount: number;
  currency: string;
  basePrice?: number;
  additionalCharge: number;
  selectedPriceVariant: ModifierListItem;
  specialRequests: string;
  selectedModifiers: Record<string, ModifierListItem[]>;
  modifiersErrors: Record<string, boolean>;
}>({
  quantity: DEFAULT_QUANTITY,
  amount: DEFAULT_AMOUNT,
  currency: DEFAULT_CURRENCY_CODE,
  specialRequests: '',
  additionalCharge: DEFAULT_ADDITIONAL_CHARGE,
  selectedModifiers: {},
  selectedPriceVariant: {},
  modifiersErrors: {},
});
