import type {
  Modifier,
  ModifierTpa,
  ModifierListItem,
  ModifierGroupRule,
  ModifierGroupListItem,
  PopulatedModifierGroupTpa,
  PopulatedModifierTpa,
  PopulatedModifierGroupRuleTpa,
} from '../types/modifiers';

export const convertPopulatedRuleToRuleType = (
  rule: PopulatedModifierGroupRuleTpa
): ModifierGroupRule =>
  ({
    required: rule.required,
    minSelections: rule.minSelections,
    maxSelections: rule.maxSelections,
  } as ModifierGroupRule);

export const convertModifierToModifierType = ({
  modifiers,
}: {
  modifiers: ModifierTpa[];
}): Modifier[] => {
  const updatedModifiers: Modifier[] = [];
  modifiers.forEach((modifier) => {
    if (modifier.id) {
      updatedModifiers.push({
        id: modifier.id,
        name: modifier.name ?? '',
      });
    }
  });

  return updatedModifiers;
};

export const convertPopulatedModifierToModifierType = (
  modifiers: PopulatedModifierTpa[]
): ModifierListItem[] => {
  const updatedModifiers: ModifierListItem[] = [];
  modifiers.forEach((modifier) => {
    if (modifier.id) {
      updatedModifiers.push({
        id: modifier.id,
        name: modifier.name ?? '',
        price: modifier.additionalChargeInfo?.additionalCharge ?? '',
        isPreSelected: modifier.preSelected ?? false,
      });
    }
  });

  return updatedModifiers;
};

export const convertPopulatedModifierGroupToModifierGroupType = (
  modifierGroups: PopulatedModifierGroupTpa[] = []
): ModifierGroupListItem[] => {
  const updatedModifierGroups: ModifierGroupListItem[] = [];
  modifierGroups.forEach((modifierGroup) => {
    if (modifierGroup.id) {
      updatedModifierGroups.push({
        id: modifierGroup.id,
        name: modifierGroup.name ?? '',
        modifiers: convertPopulatedModifierToModifierType(modifierGroup.modifiers || []),
        rule: convertPopulatedRuleToRuleType(modifierGroup.rule),
      });
    }
  });
  return updatedModifierGroups;
};
